import { render, staticRenderFns } from "./AppDialogConfirm.vue?vue&type=template&id=7b3bfb5a&"
import script from "./AppDialogConfirm.vue?vue&type=script&lang=js&"
export * from "./AppDialogConfirm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./AppDialogConfirm-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=C%3A%5CUsers%5Crmorgado%5CProjects%5Cvuejs%5Crmorgado-admin.git%5Csrc%5Ccomponents%5Capp%5CAppDialogConfirm.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VDialog,VSpacer,VToolbar,VToolbarTitle})
