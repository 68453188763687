import Vue from 'vue';
import Router from 'vue-router';
import { publicRoute, protectedRoute } from './config';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
const routes = publicRoute.concat(protectedRoute);
import store from '@/store';

Vue.use(Router);
const router = new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  routes: routes
});
// router gards
router.beforeEach((to, from, next) => {
  NProgress.start();
  const username = store.getters['auth/getUsername'];
  const currentUser = store.state.auth.user;
  const { authorize } = to.meta || [];
  const currentUserRole = currentUser ? currentUser.role : null;
  if (to.name !== 'login') {
    if (username) {
      if (authorize && authorize.length > 0 && !authorize.includes(currentUserRole)) {
        next({ name: 'page-403' });
      }
      next();
    } else {
      next({ name: 'login', query: { redirect: to.path } });
    }
  } else {
    next();
  }

  //auth route is authenticated
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
