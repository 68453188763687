const Projects = [
  {
    username: 'Dessie',
    avatar:
      'https://s3.amazonaws.com/uifaces/faces/twitter/ludwiczakpawel/128.jpg',
    name: 'Template PSD',
    deadline: '2 days later',
    progress: 90,
    color: 'pink'
  },
  {
    username: 'Jakayla',
    avatar: 'https://s3.amazonaws.com/uifaces/faces/twitter/suprb/128.jpg',
    name: 'Logo Design',
    deadline: '1 weeks later',
    progress: 70,
    color: 'success'
  },
  {
    username: 'Ludwiczakpawel',
    avatar:
      'https://s3.amazonaws.com/uifaces/faces/twitter/ludwiczakpawel/128.jpg',
    name: 'REST API',
    deadline: '1 Month later',
    progress: 50,
    color: 'info'
  },
  {
    username: 'Damenleeturks',
    avatar:
      'https://s3.amazonaws.com/uifaces/faces/twitter/damenleeturks/128.jpg',
    name: 'API Unit Test',
    deadline: '2 Month later',
    progress: 30,
    color: 'teal'
  },
  {
    username: 'Caspergrl',
    avatar: 'https://s3.amazonaws.com/uifaces/faces/twitter/caspergrl/128.jpg',
    name: 'Project Deploy',
    deadline: 'half year later',
    progress: 15,
    color: 'grey'
  }
  // {
  //   id: 1,
  //   slug: 'chat-server',
  //   name: 'chat server',
  //   description: 'A simple websocket server for chatting',
  //   body: null,
  //   sort_number: 1,
  //   status: 0,
  //   deleted_at: null,
  //   created_at: '2021-03-28T11:22:23.000000Z',
  //   updated_at: '2021-03-28T11:22:23.000000Z'
  // },
  // {
  //   id: 2,
  //   slug: 'larave-mdc',
  //   name: 'Larave MDC',
  //   description: 'A simple integration with laravel',
  //   body: null,
  //   sort_number: 2,
  //   status: 0,
  //   deleted_at: null,
  //   created_at: '2021-03-28T11:22:23.000000Z',
  //   updated_at: '2021-03-28T11:22:23.000000Z'
  // }
];
const getProject = limit => {
  return limit ? Projects.slice(0, limit) : Projects;
};

export { Projects, getProject };

const tasks = [
  {
    id: 30,
    project_id: 1,
    slug: 'test',
    name: 'Test',
    description: null,
    sort_number: 28,
    owner: null,
    status: 1,
    deleted_at: null,
    created_at: '2021-04-28T21:13:39.000000Z',
    updated_at: '2021-05-10T07:14:12.000000Z',
    project: {
      id: 1,
      slug: 'chat-server',
      name: 'chat server',
      description: 'A simple websocket server for chatting',
      body: null,
      sort_number: 1,
      status: 0,
      deleted_at: null,
      created_at: '2021-03-28T11:22:23.000000Z',
      updated_at: '2021-03-28T11:22:23.000000Z'
    }
  },
  {
    id: 31,
    project_id: 1,
    slug: 'cs',
    name: 'c&s',
    description: 'ccc',
    sort_number: 29,
    owner: null,
    status: 0,
    deleted_at: null,
    created_at: '2021-04-29T07:59:29.000000Z',
    updated_at: '2021-05-10T07:14:14.000000Z',
    project: {
      id: 1,
      slug: 'chat-server',
      name: 'chat server',
      description: 'A simple websocket server for chatting',
      body: null,
      sort_number: 1,
      status: 0,
      deleted_at: null,
      created_at: '2021-03-28T11:22:23.000000Z',
      updated_at: '2021-03-28T11:22:23.000000Z'
    }
  },
  {
    id: 34,
    project_id: 1,
    slug: 'asdsad',
    name: 'asdsad',
    description: 'asdasd',
    sort_number: 30,
    owner: null,
    status: 0,
    deleted_at: null,
    created_at: '2021-05-08T01:13:17.000000Z',
    updated_at: '2021-05-10T07:14:17.000000Z',
    project: {
      id: 1,
      slug: 'chat-server',
      name: 'chat server',
      description: 'A simple websocket server for chatting',
      body: null,
      sort_number: 1,
      status: 0,
      deleted_at: null,
      created_at: '2021-03-28T11:22:23.000000Z',
      updated_at: '2021-03-28T11:22:23.000000Z'
    }
  },
  {
    id: 35,
    project_id: 1,
    slug: 'gfh',
    name: 'gfh',
    description: 'gfhgf',
    sort_number: 31,
    owner: 'gfh',
    status: 1,
    deleted_at: null,
    created_at: '2021-05-10T01:57:06.000000Z',
    updated_at: '2021-05-10T07:14:06.000000Z',
    project: {
      id: 1,
      slug: 'chat-server',
      name: 'chat server',
      description: 'A simple websocket server for chatting',
      body: null,
      sort_number: 1,
      status: 0,
      deleted_at: null,
      created_at: '2021-03-28T11:22:23.000000Z',
      updated_at: '2021-03-28T11:22:23.000000Z'
    }
  }
];
