import { render, staticRenderFns } from "./AppToolbar.vue?vue&type=template&id=041a5ec4&scoped=true&"
import script from "./AppToolbar.vue?vue&type=script&lang=js&"
export * from "./AppToolbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041a5ec4",
  null
  
)

/* custom blocks */
import block0 from "./Menu-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=C%3A%5CUsers%5Crmorgado%5CProjects%5Cvuejs%5Crmorgado-admin.git%5Csrc%5Ccomponents%5CAppToolbar.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VAppBarNavIcon,VAvatar,VBadge,VBreadcrumbs,VBtn,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemTitle,VMenu,VSpacer,VToolbar,VToolbarItems})
