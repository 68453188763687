<i18n src="./Menu-i18n.yaml"></i18n>
<template>
  <v-app-bar :color="headerColor" dark app>
    <v-app-bar-nav-icon @click="handleDrawerToggle" />
    <v-spacer />
    <v-toolbar-items>
      <v-btn icon href="https://github.com">
        <v-icon>mdi-github</v-icon>
      </v-btn>
      <v-btn icon @click="handleFullScreen()">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
      <v-menu
        offset-y
        origin="center center"
        class="elelvation-1"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon text slot="activator" v-on="on">
            <v-badge color="red" overlap>
              <span slot="badge">3</span>
              <v-icon medium>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <notification-list></notification-list>
      </v-menu>
      <FieldSelectLanguage />
      <v-menu offset-y origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon large text slot="activator" v-on="on">
            <v-avatar size="30px">
              <img :src="getAvatar" :alt="getUsername" />
            </v-avatar>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item
            v-for="(item, index) in profileMenus"
            :to="!item.href ? { name: item.name } : null"
            :href="item.href"
            @click="item.click"
            :disabled="item.disabled"
            :target="item.target"
            rel="noopener"
            :key="index"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <v-toolbar tag="div" dense slot="extension" color="white" light>
      <v-icon>mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-3" />
      <v-spacer></v-spacer>
      <v-btn icon small color="black">
        <v-icon v-text="'mdi-arrow-left'" @click="handleGoBack" />
      </v-btn>
    </v-toolbar>
  </v-app-bar>
</template>
<script>
import NotificationList from '@/components/widgets/list/NotificationList';
import Util from '@/util';
import { mapGetters, mapState } from 'vuex';
import FieldSelectLanguage from '@/components/fields/SelectLanguage';
export default {
  name: 'AppToolbar',
  components: {
    NotificationList,
    FieldSelectLanguage
  },
  data() {
    return {
      profileMenus: [
        {
          icon: 'mdi-account',
          href: '#',
          title: 'Profile',
          click: this.handleProfile
        },
        {
          icon: 'mdi-cog',
          href: '#',
          title: 'Settings',
          click: this.handleSetting
        },
        {
          icon: 'mdi-power',
          href: '#',
          title: 'Logout',
          click: this.handleLogut
        }
      ]
    };
  },
  computed: {
    ...mapState('app', ['headerColor']),
    ...mapGetters('auth', ['getAvatar', 'getUsername']),
    breadcrumbs() {
      const { matched } = this.$route;
      return matched.map((route, index) => {
        const to =
          index === matched.length - 1
            ? this.$route.path
            : route.path || route.redirect;
        const text = this.$t('menu_' + route.meta.title);
        return {
          text: text,
          to: to,
          exact: true,
          disabled: false
        };
      });
    }
  },
  methods: {
    handleDrawerToggle() {
      this.$emit('side-icon:click');
    },
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    handleLogut() {
      this.$root
        .$dialogConfirm('Logout', 'Are you sure to logout?')
        .then(confirm => {
          if (confirm) {
            this.$store.dispatch('auth/logout');
            this.$toast.success('Logout successfull');
            this.$router.push('/auth/login');
          }
        });
    },
    handleSetting() {},
    handleProfile() {},
    handleGoBack() {
      this.$router.go(-1);
    }
  },
  created() {}
};
</script>

<style lang="sass" scoped></style>
