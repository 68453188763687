var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"themeSetting"},[_c('v-toolbar',{attrs:{"color":_vm.headerColor}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Theme Settings")]),_c('v-spacer')],1),_c('v-container',{attrs:{"pa-0":"","ma-0":""}},[_c('v-row',{attrs:{"column":""}},[_c('v-col',[_c('SettingsCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Language ")]},proxy:true},{key:"content",fn:function(){return [_c('FieldSelectLanguage')]},proxy:true}])}),_c('SettingsCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Theme Color Mode ")]},proxy:true},{key:"content",fn:function(){return [_c('v-btn',{staticClass:"mr-4 mb-4",class:_vm.sideBarOption === 'light' ? 'primary' : 'white warning--text',attrs:{"fab":"","small":"","dark":""},on:{"click":_vm.onThemeChange}},[_c('v-icon',{domProps:{"innerHTML":_vm._s(
                  _vm.sideBarOption === 'light'
                    ? 'mdi-weather-night'
                    : 'mdi-white-balance-sunny'
                )}})],1)]},proxy:true}])}),_c('SettingsCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Theme Orientation ")]},proxy:true},{key:"content",fn:function(){return [_c('v-btn',{staticClass:"mr-4 mb-4 primary",attrs:{"fab":"","small":"","dark":""},on:{"click":_vm.onThemeOrientationChange}},[_c('v-icon',{domProps:{"innerHTML":_vm._s(
                  _vm.themeOrientation === 'right'
                    ? 'mdi-dock-right'
                    : 'mdi-dock-left'
                )}})],1)]},proxy:true}])}),_c('SettingsCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Theme Primary Color ")]},proxy:true},{key:"content",fn:function(){return [_c('ColorPalette',{attrs:{"colors":_vm.primaryColorOptions},model:{value:(_vm.primaryColor),callback:function ($$v) {_vm.primaryColor=$$v},expression:"primaryColor"}})]},proxy:true}])}),_c('SettingsCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Theme Secondary Color ")]},proxy:true},{key:"content",fn:function(){return [_c('ColorPalette',{attrs:{"colors":_vm.secondaryColorOptions},model:{value:(_vm.secondaryColor),callback:function ($$v) {_vm.secondaryColor=$$v},expression:"secondaryColor"}})]},proxy:true}])}),_c('SettingsCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Sidebar Color ")]},proxy:true},{key:"content",fn:function(){return [_c('ColorPalette',{attrs:{"colors":_vm.sidebarColorOptions},model:{value:(_vm.sidebarColor),callback:function ($$v) {_vm.sidebarColor=$$v},expression:"sidebarColor"}})]},proxy:true}])}),_c('SettingsCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Header Color ")]},proxy:true},{key:"content",fn:function(){return [_c('ColorPalette',{attrs:{"colors":_vm.headerColorOptions},model:{value:(_vm.headerColor),callback:function ($$v) {_vm.headerColor=$$v},expression:"headerColor"}})]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }