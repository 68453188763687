<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :color="computedColor"
    :style="styles"
    depressed
    :class="computedCls"
    tile
    :dark="disabled && dark ? false : true"
    :disabled="disabled"
    :outlined="computedOutlined"
    :icon="icon"
    raised
    rounded
  >
    <template v-if="btnTypeIcon || btnIcon">
      <v-icon v-if="btnIcon" small class="mr-1" v-html="btnIcon" />
      <v-icon v-else small class="mr-1" v-html="btnTypeIcon" />
    </template>
    <slot />
  </v-btn>
</template>

<script>
export default {
    name: "BaseBtn",

    props: {
        square: Boolean,
        color: {
            type: String,
            default: "primary"
        },
        dark: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        icon: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: null
        },
        btnIcon: {
            type: String,
            default: null
        },
    },

    inject: ["theme"],

    data: () => ({
        buttons: [
            {
                type: "primary",
                color: "primary"
            },
            {
                type: "secondary",
                color: "secondary",
                outlined: true
            },
            {
                type: "info",
                color: "info",
                icon: "mdi-information"
            },
            {
                type: "warning",
                color: "warning",
                icon: "mdi-alert"
            },
            {
                type: "error",
                color: "error",
                icon: "mdi-close-circle"
            },
            {
                type: "success",
                color: "green",
                icon: "mdi-check-circle"
            },
            {
                type: "close",
                color: "secondary",
                icon: "mdi-close",
                outlined: true
            },
            {
                type: "save",
                color: "primary",
                icon: "mdi-content-save"
            },
            {
                type: "delete",
                color: "error",
                icon: "mdi-delete"
            }
        ]
    }),

    methods: {
        _getBtnByType(btnType){
            return this.buttons.find((btn) => btn.type === btnType);
        }
    },

    computed: {
        styles() {
            return {
                borderRadius: this.square ? "10px" : undefined,
                textTransform: "none !important"
            };
        },
        computedCls() {
            let cls = "";
            if (this.theme.isDark && this.color === "primary") {
                cls = "secondary--text";
                if (this.outlined || this.icon) {
                    cls = "white--text";
                }
            }

            if (this.btn && !this.color && !this.icon) {
                if (this.btnTypeOutlined || this.btnType === "secondary") {
                    if (this.btnTypePrimary) {
                        cls = `${this.btnTypePrimary.color}--text`;
                    }
                }
            }
            return cls;
        },
        btn() {
            return this._getBtnByType(this.type);
        },
        btnType() {
            return this.btn ? this.btn.type : false;
        },
        btnTypeColor() {
            return this.btn ? this.btn.color : null;
        },
        btnTypeOutlined() {
            return this.btn ? this.btn.outlined : null;
        },
        btnTypeIcon() {
            return this.btn ? this.btn.icon : null;
        },
        btnTypePrimary() {
            return this._getBtnByType("primary");
        },
        btnTypeSecondary() {
            return this._getBtnByType("secondary");
        },
        computedColor() {
            if (!this.btnType && this.color){
                return this.color;
            }
            if (this.btnType) {
                if (this.btnTypeOutlined || this.btnType === "secondary") {
                    if (this.btnTypePrimary) {
                        return this.btnTypePrimary.color;
                    }
                }
                if (this.btnTypeColor){
                    return this.btnTypeColor;

                }
            }
            return this.color;
        },
        computedOutlined() {
            if (this.outlined) {
                return this.outlined;
            }
            if (this.btnType && this.btnTypeOutlined) {
                return true;
            }
            return false;
        }
    }
};
</script>

<style></style>
