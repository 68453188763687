<i18n src="./Menu-i18n.yaml"></i18n>
<template>
  <v-navigation-drawer
    app
    class="app-drawer"
    :mini-variant.sync="mini"
    v-model="drawer"
    :width="drawerWidth"
    fixed
    dark
    :expand-on-hover="pinMini"
    :color="sidebarColor"
    :permanent="drawer && $vuetify.breakpoint.smAndUp"
  >
    <v-list subheader two-line>
      <v-list-item>
        <v-avatar size="30px">
          <img :src="computeLogo" height="36" alt="RMORGADO Amdin" />
        </v-avatar>
        <v-list-item-content class="pl-2">
          <v-list-item-title>
            <span class="hidden-sm-and-down "
              >RMORGADO ADMIN</span
            ></v-list-item-title
          >
        </v-list-item-content>
        <v-btn color="secondary" icon x-small @click="pinMini = !pinMini"
          ><v-icon
            v-html="pinMini ? 'mdi-circle-outline' : 'mdi-circle-slice-8'"
          ></v-icon
        ></v-btn>
      </v-list-item>
      <v-list-item>
        <v-avatar size="40px">
          <img :src="getAvatar" :alt="getUsername" />
        </v-avatar>
        <v-list-item-content class="pl-4 ">
          <v-list-item-title>{{ getUsername }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-btn class="mr-5" icon x-small><v-icon>mdi-cog</v-icon></v-btn>
            <v-btn class="mr-5" icon x-small><v-icon>mdi-email</v-icon></v-btn>
            <v-btn class="mr-5" icon x-small @click="handleLogut"
              ><v-icon>mdi-logout-variant</v-icon></v-btn
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dark :dense="drawerWidth !== 64" class="pa-0 secondary--text">
      <template v-for="(item, key) in computeMenu">
        <template v-if="item.children && item.children.length > 0">
          <v-list-group
            class=""
            :key="key"
            no-action
            :to="item.path"
            color="secondary"
          >
            <template v-slot:prependIcon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon" />
                </template>
                <span>
                  {{ $t('menu_' + item.meta.title) }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:activator>
              <v-list-item-content class="">
                <v-list-item-title v-text="$t('menu_' + item.meta.title)" />
              </v-list-item-content>
            </template>
            <!-- Items with childrens -->
            <v-list-item
              :class="drawerWidth === 64 ? 'pl-4' : ''"
              v-for="subItem in item.children"
              :key="subItem.name"
              :to="subItem.path"
              v-show="!subItem.meta.hiddenInMenu && isAuthorized(subItem)"
            >
              <!-- <template v-if="drawerWidth === 64"> -->
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-text="subItem.meta.icon"
                    />
                  </template>
                  <span>{{ $t('menu_' + subItem.meta.title) }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <!-- </template> -->
              <!-- <template v-else> -->
              <v-list-item-content>
                <v-list-item-title v-text="$t('menu_' + subItem.meta.title)" />
              </v-list-item-content>
              <!-- </template> -->
            </v-list-item>
          </v-list-group>
        </template>
        <template v-else>
          <v-list-item
            :key="key"
            :to="item.path"
            v-show="!item.meta.hiddenInMenu && isAuthorized(item)"
          >
            <v-list-item-icon>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon" />
                </template>
                <span>{{ $t('menu_' + item.meta.title) }}</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content class="" v-if="drawerWidth !== 64">
              <v-list-item-title v-text="$t('menu_' + item.meta.title)" />
            </v-list-item-content>
            <v-list-item-action v-if="item.meta.new">
              <v-icon color="green">mdi-new-box </v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { protectedRoute as routes } from '@/router/config';
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'AppDrawer',
  components: {},
  props: {
    expanded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pinMini: false,
      mini: false,
      drawerWidth: 256,
      drawer: true,
      scrollSettings: {
        maxScrollbarLength: 160
      }
    };
  },

  computed: {
    ...mapState('app', ['sidebarColor']),
    ...mapGetters('auth', ['getAvatar', 'getUsername', 'getUserRole']),
    computeLogo() {
      return '/static/logo.png';
    },
    computeMenu() {
      return routes[0].children;
    }
  },
  created() {},

  methods: {
    isAuthorized(item) {
      const authorizeRoles = item && item.meta ? item.meta.authorize : [];
      const userRole = this.getUserRole;
      if (authorizeRoles && authorizeRoles.length > 0) {
        if (
          !userRole ||
          !authorizeRoles.includes(userRole)
        ) {
          return false;
        }
      }
      return true;
    },
    handleDrawerCollapse() {
      // this.drawerWidth = this.drawerWidth === 256 ? 64 : 256;
      if (this.mini && !this.pinMini) {
        this.drawerWidth = 64;
      } else {
        this.drawerWidth = 256;
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    handleLogut() {
      this.$root
        .$dialogConfirm('Logout', 'Are you sure to logout?')
        .then(confirm => {
          if (confirm) {
            this.$store.dispatch('auth/logout');
            this.$toast.success('Logout successfull');
            this.$router.push('/auth/login');
          }
        });
    }
  },
  watch: {
    pinMini: {
      handler(val) {
        this.mini = val;
      },
      immediate: true
    },
    mini: {
      handler(val) {
        this.handleDrawerCollapse();
      },
      immediate: true
    }
  }
};
</script>

<style lang="sass" scoped>
.app-drawer
  overflow: hidden !important
</style>
