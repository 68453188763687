// import request from '@/util/request';

import axios from 'axios';

const state = {
  projects: [],
  taskStatus: [
    {
      text: 'Todo',
      value: 0
    },
    {
      text: 'Progress',
      value: 1
    },
    {
      text: 'Done',
      value: 2
    }
  ]
};

// mutations
const mutations = {
  SET_PROJECTS(state, data) {
    state.projects = data;
  }
};

// getters
const getters = {
  getProjectList: state => {
    return state.projects.map(item => {
      return {
        text: item.name,
        value: item.id
      };
    });
  },
  getTaskStatus: state => state.taskStatus,
  getTaskStatusByValue: state => value => {
    const find = state.taskStatus.find(item.value === value);
    return find.text;
  }
};

// actions
const actions = {
  fetchAllProjects(context) {
    console.warn("fetchAllProjects");
    return new Promise((resolve, reject) => {
      return axios({
        // url: '/auth/login',
        url: '/project/',
        method: 'get',
      })
        .then(resp => {
          console.warn("resp", resp);
          const respData = resp.data;
          const projects = respData.projects;
          context.commit('SET_PROJECTS', projects);
          resolve(resp);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchTask(context, query) {
    console.warn("fetchTask", query.projectId);
    return new Promise((resolve, reject) => {
      return axios({
        // url: '/auth/login',
        url: `/task/${query.projectId}`,
        method: 'get',
        // params: query
      })
        .then(resp => {
          console.warn("resp", resp);
          const project = resp.data;
          context.commit('SET_PROJECT', project);
          resolve(resp);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  // createTask(context, data) {
  //   return request({
  //     url: `task`,
  //     method: 'post',
  //     data: data
  //   }).then(resp => {
  //     return resp;
  //   });
  // },
  // updateTask(context, { id, data }) {
  //   return request({
  //     url: `task/${id}`,
  //     method: 'put',
  //     data: data
  //   }).then(resp => {
  //     return resp;
  //   });
  // },
  // updateTaskStatus(context, { id, status }) {
  //   return request({
  //     url: `task/${id}`,
  //     method: 'put',
  //     data: {
  //       status: status
  //     }
  //   }).then(resp => {
  //     return resp;
  //   });
  // },
  // deleteTask(context, id) {
  //   return request({
  //     url: `task/${id}`,
  //     method: 'delete'
  //   });
  // },
  // getTaskById(context, id) {
  //   return request({
  //     url: `task/${id}`,
  //     method: 'get'
  //   }).then(resp => {
  //     return resp;
  //   });
  // }
};



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
