<template>
  <v-app :dark="true">
    <router-view></router-view>
    <!-- theme setting -->
    <v-btn
      small
      fab
      dark
      fixed
      top="top"
      right="right"
      class="setting-fab"
      color="red"
      @click="openThemeSettings"
    >
      <v-icon>mdi-spin mdi-cog</v-icon>
    </v-btn>
    <!-- setting drawer -->
    <v-navigation-drawer
      class="setting-drawer"
      temporary
      right
      v-model="rightDrawer"
      hide-overlay
      fixed
      width="400"
    >
      <theme-settings />
    </v-navigation-drawer>
    <AppDialogConfirm ref="confirm" />
  </v-app>
</template>

<script>
import ThemeSettings from '@/components/ThemeSettings';
export default {
  components: {
    ThemeSettings
  },
  data() {
    return {
      rightDrawer: false
    };
  },
  methods: {
    openThemeSettings() {
      this.$vuetify.goTo(0);
      this.rightDrawer = !this.rightDrawer;
    }
  },
  mounted() {
    this.$root.$dialogConfirm = this.$refs.confirm.open;
  }
};
</script>

<style lang="sass" scoped>
.setting-fab
  top: 90% !important
  right: 20
</style>
