import { render, staticRenderFns } from "./AppDrawer.vue?vue&type=template&id=97415efa&scoped=true&"
import script from "./AppDrawer.vue?vue&type=script&lang=js&"
export * from "./AppDrawer.vue?vue&type=script&lang=js&"
import style0 from "./AppDrawer.vue?vue&type=style&index=0&id=97415efa&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97415efa",
  null
  
)

/* custom blocks */
import block0 from "./Menu-i18n.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=C%3A%5CUsers%5Crmorgado%5CProjects%5Cvuejs%5Crmorgado-admin.git%5Csrc%5Ccomponents%5CAppDrawer.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VBtn,VIcon,VList,VListGroup,VListItem,VListItemAction,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VNavigationDrawer,VTooltip})
