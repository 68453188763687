<template>
  <ContentWrapper>
    <div
      class="app-sidebar white"
      :class="drawer ? 'open' : undefined"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <div class="app-sidebar-header white  py-6">
        <slot name="app-sidebar-header-content"> </slot>
      </div>
      <section class="ps-container">
        <div class="app-sidebar-body mt-6">
          <slot name="app-sidebar-body-content"> </slot>
        </div>
      </section>
    </div>
    <v-navigation-drawer
      v-else
      v-model="drawer"
      absolute
      :permanent="$vuetify.breakpoint.mdAndUp"
      class="app-overlay"
      :class="drawer ? 'open' : undefined"
    >
      <div class="app-sidebar-header white py-5">
        <slot name="app-sidebar-header-content"> </slot>
      </div>
      <section class="ps-container">
        <div class="app-sidebar-body mt-6">
          <slot name="app-sidebar-body-content"> </slot>
        </div>
      </section>
    </v-navigation-drawer>
    <div class="app-content" style="width=100%">
      <div class="app-header white ml-5" :style="$vuetify.breakpoint.smAndDown ? 'width:100%;' : 'width: calc(100% - 330px)'">
        <div class="d-flex flex-wrap align-baseline">
          <div>
            <div class="d-sm-block d-md-none">
              <v-btn
                icon
                class="d-sm-block d-md-none"
                @click="drawer = !drawer"
              >
                <v-icon class="dark--text">
                  mdi-menu-open
                </v-icon>
              </v-btn>
            </div>
          </div>
          <slot name="app-header-content"> </slot>
          <slot name="app-header-content-append"> </slot>
        </div>
      </div>
      <section
        class="ps-container"
        :class="$vuetify.breakpoint.xsOnly ? 'pt-4' : undefined"
        style="height: 100%"
      >
        <div
          class="app-body mt-16 px-8 mb-10 overflow-y-auto"
          style="max-height: calc(100vh - 100px)"
        >
          <slot name="app-body-content"> </slot>
        </div>
      </section>
    </div>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from '@/components/wrappers/ContentWrapper.vue';
export default {
  name: 'AppWrapper',
  components: {
    ContentWrapper
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass">
.app-overlay
  position: absolute
  content: ""
  width: 100%
  height: 100%
  top: 0
  left: 0
  background: rgba(0,0,0,.4)
  display: none
  z-index: 2
  transition: all .3s ease-in
  &.open
    display: block
    transition: all .3s ease-in


.app-sidebar-header
  padding: .75rem 1.25rem
  margin-bottom: 0
  position: absolute
  top: 0
  width: 100%
  z-index: 1

.ps-container
  height: 100%
  position: inherit!important

.app-sidebar-body
  padding-top: 3.375rem
  width: 280px

.app-sidebar
  width: 280px
  position: relative
  transition: all .3s ease-in

.app-content
  width: calc(100% - 280px)

.app-header
  padding: .75rem 1.25rem
  margin-bottom: 0
  border-bottom: 1px solid rgba(0,0,0,.125)
  position: absolute
  top: 0
  z-index: 1

@media only screen and (max-width: 959px)
.content-wrapper .app-content
    width: 100%

.content-wrapper .app-content .app-body
    flex: 1 1 auto
    height: calc(100% - 130px)
    padding-top: 3.375rem
</style>
