const state = {
  mode: 'light',
  primaryColor: 'pink',
  sidebarColor: 'pink',
  headerColor: 'blue',
  secondaryColor: 'blue'
};

const mutations = {
  setPrimaryColor(state, payload) {
    state.primaryColor = payload;
  },
  setSecondaryColor(state, payload) {
    state.secondaryColor = payload;
  },
  setSidebarColor(state, payload) {
    state.sidebarColor = payload;
  },
  setHeaderColor(state, payload) {
    state.headerColor = payload;
  }
};

const getters = {
  getPrimaryColor: state => {
    return colors[state.primaryColor].base;
  },
  getSecondaryColor: state => {
    return colors[state.secondaryColor].base;
  },
  getSidebarColor: state => {
    return colors[state.sidebarColor].base;
  },
  getHeaderColor: state => {
    return colors[state.headerColor].base;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
