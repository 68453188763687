import { createServer, Model, Response } from 'miragejs';
import _ from 'lodash';

import { users } from '@/api/auth';
import { Projects } from '@/api/projects';

export function makeServer({ environment = 'development' } = {}) {
  let server = createServer({
    environment,

    models: {
      user: Model,
      project: Model
    },

    seeds(server) {
      _.forEach(users, (user, idx) => {
        server.create('user', {
          name: user.name,
          id: user.uuid,
          email: user.email,
          username: user.username,
          jobTitle: user.jobTitle,
          phone: user.phone,
          avatar: user.avatar,
          role: user.role,
          address: {
            street: user.address.street,
            suite: user.address.suite,
            city: user.address.city,
            state: user.address.state,
            country: user.address.country,
            zipcode: user.address.zipcode,
            geo: {
              lat: user.address.geo.lat,
              lng: user.address.geo.lng
            }
          }
        });
      });
      // server.create("user", { name: "Bob" })
      // server.create("user", { name: "Alice" })

      _.forEach(Projects, project => {
        server.create('project', {
          username: project.username,
          avatar: project.avatar,
          name: project.name,
          deadline: project.deadline,
          progress: project.progress,
          color: project.color
        });
      });
    },

    routes() {
      this.namespace = 'auth';
      this.post('/login', (schema, request) => {
        let payload = JSON.parse(request.requestBody);
        let username = payload.username;
        let password = payload.password;

        if (!password || !username) {
          return new Response(400, {
            errors: ['Password or Username is missing']
          });
        }
        let user = schema.users.findBy({ username });
        if (user) {
          return user;
        } else {
          return new Response(404, { errors: ['Not Found'] });
        }
      });

      this.namespace = 'api';

      this.get('/users', schema => {
        return schema.users.all();
      });

      this.namespace = 'project';

      this.get('/', schema => {
        console.warn("getAllProjects");
        return schema.projects.all();

      });


      this.namespace = 'task';

      this.get('/:project_id', (schema, request) => {
        let project_id = request.params.project_id;
        console.warn("gettask", project_id);
        const project = schema.projects.find(project_id);
        console.warn("project", project);
        return project

      });
    }
  });

  return server;
}
