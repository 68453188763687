import { LayoutAuth, LayoutDefault, RouteWrapper } from '@/components/layouts';
import { Role } from '@/helpers/roles';
export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue')
  },
  {
    path: '/auth',
    component: LayoutAuth,
    meta: {
      title: 'Login'
    },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          title: 'Login'
        },
        component: () => import('@/views/auth/Login.vue')
      }
    ]
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'Not Found'
    },
    component: () => import('@/views/error/NotFound.vue')
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'Server Error'
    },
    component: () => import('@/views/error/Error.vue')
  }
];

export const protectedRoute = [
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'home',
      group: 'apps',
      icon: ''
    },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          title: 'dashboard',
          group: 'apps',
          icon: 'mdi-view-dashboard'
        },
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: '/page',
        component: RouteWrapper,
        meta: {
          title: 'pages',
          group: 'pages',
          icon: 'mdi-circle-medium'
        },
        redirect: '/page/403',
        children: [
          {
            path: '/page/403',
            name: 'page-403',
            meta: {
              title: 'access_denied',
              group: 'pages',
              icon: 'mdi-circle-medium'
            },
            component: () => import('@/views/error/Deny.vue')
          },
          {
            path: '/page/500',
            name: 'page-500',
            meta: {
              title: 'server_error',
              group: 'pages',
              icon: 'mdi-circle-medium'
            },
            redirect: '/500'
          },
          {
            path: '/page/404',
            name: 'page-404',
            meta: {
              title: 'not_found',
              group: 'pages',
              icon: 'mdi-circle-medium'
            },
            redirect: '/404'
          },
          {
            path: '/page/login',
            name: 'page-login',
            meta: {
              title: 'login',
              group: 'pages',
              icon: 'mdi-circle-medium'
            },
            redirect: '/auth/login'
          },
          {
            path: '/page/admin',
            name: 'page-admin',
            meta: {
              title: 'admin',
              group: 'pages',
              icon: 'mdi-circle-medium',
              authorize: [Role.ADMIN]
            },
            component: () => import('@/views/admin/view.vue')
          },
          {
            path: '/page/editor',
            name: 'page-editor',
            meta: {
              title: 'editor',
              group: 'pages',
              icon: 'mdi-circle-medium',
              authorize: [Role.EDITOR]
            },
            component: () => import('@/views/editor/view.vue')
          }
        ]
      },
      {
        path: '/app',
        component: RouteWrapper,
        meta: {
          title: 'apps',
          group: 'apps',
          icon: 'mdi-circle-medium'
        },
        redirect: '/app/todo',
        children: [
          {
            path: '/app/todo',
            name: 'app-todo',
            meta: {
              title: 'app_todo',
              group: 'apps',
              icon: 'mdi-circle-medium'
            },
            component: () => import('@/views/apps/TodoAppView.vue')
          },
          {
            path: '/app/task',
            meta: {
              title: 'app_task',
              group: 'apps',
              icon: 'mdi-calendar-check'
            },
            name: 'app-task',
            component: () => import('@/views/task/TaskList.vue')
          },
          {
            path: '/app/kanboard',
            meta: {
              title: 'app_kanboard',
              group: 'apps',
              icon: 'mdi-calendar-check'
            },
            name: 'app-kanboard',
            component: () => import('@/views/task/KanBoard.vue')
          }

        ]
      },
    ]
  }
];
