<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      scrollable
      :maxWidth="maxWidth"
      v-on="$listeners"
      v-bind="$attrs"
    >
      <v-card
        flat
        class="mx-auto"
        :height="height"
        :max-height="maxHeight"
        :min-height="minHeight"
      >
        <v-toolbar class="primary--text" flat>
          <v-icon v-html="icon" color="primary"></v-icon>
          <v-toolbar-title class="pl-2">
            <slot name="toolbar-title">
              <span v-html="title"></span>
            </slot>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <slot name="toolbar-items"></slot>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="close" color="primary">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span v-if="closeIconText">
                {{ closeIconText }}
              </span>
              <span v-else>
                {{ $t('close') }}
              </span>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pa-1 pt-0 mt-0 fill-height">
          <slot name="content"> </slot>
        </v-card-text>
        <v-card-actions>
          <slot name="actions"> </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'BaseDialog',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    maxWidth: {
      type: String,
      Number,
      default: '1024'
    },
    height: {
      type: String,
      Number,
      default: undefined
    },
    maxHeight: {
      type: String,
      Number,
      default: undefined
    },
    minHeight: {
      type: String,
      Number,
      default: undefined
    },
    closeIconText: {
      type: String,
      default: undefined
    }
  },

  methods: {
    close() {
      this.$emit('close');
      this.$emit('input', false);
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set() {
        this.close();
      }
    }
  }
};
</script>

<style></style>
