import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
// import Vuetify from "vuetify/lib/framework";
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib';
import VuetifyToast from 'vuetify-toast-snackbar-ng';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
});

//import locales
import de from 'vuetify/es5/locale/de';
import fr from 'vuetify/es5/locale/fr';
import it from 'vuetify/es5/locale/it';
import en from 'vuetify/es5/locale/en';

Vue.use(VuetifyToast, {
  x: 'center', // default
  y: 'top', // default
  color: 'info', // default
  icon: '',
  iconColor: '', // default
  classes: ['body-2'],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: true, // default
  closeText: '', // default
  closeIcon: 'mdi-close', // default
  closeColor: '', // default
  slot: [], //default
  shorts: {
    custom: {
      color: 'purple'
    }
  },
  property: '$toast' // default
});

const opts = {
  lang: {
    locales: { de, fr, it, en },
    current: 'de'
  },
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#304156',
        secondary: '#FFFFFF',
        accent: '#c40c2c',
        success: '#4CAF50',
        info: '#1160cf',
        warning: '#FFC107',
        error: '#F44336',
        svtiRed: '#F50F38'
      },
      light: {
        primary: '#304156',
        secondary: '#FFFFFF',
        accent: '#c40c2c',
        success: '#4caf50',
        info: '#1160cf',
        warning: '#ff9800',
        error: '#F44336',
        svtiRed: '#F50F38'
      }
    }
  }
};

export default new Vuetify(opts);
