import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './lang/lang.js';
import { makeServer } from '@/api/mock/server';
import './plugins/app-components'; // register App Components
import './plugins/base-components'; // register Base Components
import axios from 'axios';
Vue.config.productionTip = false;

// if (process.env.NODE_ENV === 'development') {
//   makeServer();
// }
makeServer();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
