<template>
  <Wrapper>
    <div class="content-wrapper">
      <slot />
    </div>
  </Wrapper>
</template>
<script>
import Wrapper from '@/components/wrappers/Wrapper.vue';
export default {
  name: 'ContentWrapper',
  components: {
    Wrapper
  }
};
</script>

<style lang="sass">
.content-wrapper
  position: relative
  display: flex
  overflow: hidden
  height: calc(100vh - 200px)
</style>
