<i18n src="./SelectLanguage-i18n.yaml"></i18n>
<template>
  <v-menu
    offset-y
    origin="center center"
    class="elelvation-1"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn text slot="activator" v-on="on">
        <v-icon medium>mdi-translate</v-icon>
        <span class="ml-2"> {{ localeText }} </span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group v-model="$root.$i18n.locale">
        <v-list-item
          @click="handleChangeLocale(item)"
          v-for="item in availableLanguages"
          :key="item.value"
          :value="item.value"
        >
          <v-list-item-title v-text="item.text" />
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'FieldSelectLanguage',
  computed: {
    availableLanguages() {
      const locales = ['de', 'en', 'pt'];
      return Object.keys(locales).map(lang => {
        return {
          text: this.$t(`lang-${locales[lang]}`),
          value: locales[lang]
        };
      });
    },
    localeText() {
      const find = this.availableLanguages.find(
        item => item.value === this.$root.$i18n.locale
      );
      return find.text;
    }
  },
  methods: {
    handleChangeLocale({ value }) {
      this.$root.$i18n.locale = value.toLowerCase();
      this.$root.$vuetify.lang.current = value.toLowerCase();
    }
  }
};
</script>

<style></style>
