<template>
  <div class="themeSetting">
    <v-toolbar :color="headerColor">
      <v-toolbar-title class="white--text">Theme Settings</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-container pa-0 ma-0>
      <v-row column>
        <v-col>
          <SettingsCard>
            <template v-slot:title>
              Language
            </template>
            <template v-slot:content>
              <FieldSelectLanguage />
            </template>
          </SettingsCard>
          <SettingsCard>
            <template v-slot:title>
              Theme Color Mode
            </template>
            <template v-slot:content>
              <v-btn
                class="mr-4 mb-4"
                fab
                small
                dark
                :class="
                  sideBarOption === 'light' ? 'primary' : 'white warning--text'
                "
                @click="onThemeChange"
              >
                <v-icon
                  v-html="
                    sideBarOption === 'light'
                      ? 'mdi-weather-night'
                      : 'mdi-white-balance-sunny'
                  "
                ></v-icon>
              </v-btn>
            </template>
          </SettingsCard>
          <SettingsCard>
            <template v-slot:title>
              Theme Orientation
            </template>
            <template v-slot:content>
              <v-btn
                class="mr-4 mb-4 primary"
                fab
                small
                dark
                @click="onThemeOrientationChange"
              >
                <v-icon
                  v-html="
                    themeOrientation === 'right'
                      ? 'mdi-dock-right'
                      : 'mdi-dock-left'
                  "
                ></v-icon>
              </v-btn>
            </template>
          </SettingsCard>
          <SettingsCard>
            <template v-slot:title>
              Theme Primary Color
            </template>
            <template v-slot:content>
              <ColorPalette
                :colors="primaryColorOptions"
                v-model="primaryColor"
              />
            </template>
          </SettingsCard>
          <SettingsCard>
            <template v-slot:title>
              Theme Secondary Color
            </template>
            <template v-slot:content>
              <ColorPalette
                :colors="secondaryColorOptions"
                v-model="secondaryColor"
              />
            </template>
          </SettingsCard>
          <SettingsCard>
            <template v-slot:title>
              Sidebar Color
            </template>
            <template v-slot:content>
              <ColorPalette
                :colors="sidebarColorOptions"
                v-model="sidebarColor"
              />
            </template>
          </SettingsCard>
          <SettingsCard>
            <template v-slot:title>
              Header Color
            </template>
            <template v-slot:content>
              <ColorPalette
                :colors="headerColorOptions"
                v-model="headerColor"
              />
            </template>
          </SettingsCard>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import colors from 'vuetify/es5/util/colors';
import FieldSelectLanguage from '@/components/fields/SelectLanguage';
export default {
  components: {
    FieldSelectLanguage,
    ColorPalette: () => import('@/components/widgets/ColorPalette.vue'),
    SettingsCard: () => import('@/components/widgets/card/SettingsCard.vue')
  },
  data() {
    return {
      sideBarOption: 'light',
      themeOrientation: 'left',
      colors: colors
    };
  },
  computed: {
    sidebarColorOptions() {
      return [
        'blue',
        'teal',
        '#304156',
        'red',
        'orange',
        'purple',
        'indigo',
        'cyan',
        'pink',
        'green'
      ];
    },
    headerColorOptions() {
      return [
        'blue',
        'teal',
        '#304156',
        'red',
        'orange',
        'purple',
        'indigo',
        'cyan',
        'pink',
        'green'
      ];
    },
    primaryColorOptions() {
      return [
        'blue',
        'teal',
        '#304156',
        'red',
        'orange',
        'purple',
        'indigo',
        'cyan',
        'pink',
        'green'
      ];
    },
    secondaryColorOptions() {
      return [
        'blue',
        'teal',
        '#304156',
        'red',
        'orange',
        'purple',
        'indigo',
        'cyan',
        'pink',
        'green'
      ];
    },
    availableLanguages() {
      const { locales } = this.$vuetify.lang;
      return Object.keys(locales).map(lang => {
        return {
          text: locales[lang].label,
          value: lang
        };
      });
    },
    headerColor: {
      get() {
        return this.$store.state.app.headerColor;
      },
      set(value) {
        const color = this.getColorHexOrNull(value);
        this.$store.commit('app/setHeaderColor', color ? color : value);
      }
    },
    sidebarColor: {
      get() {
        return this.$store.state.app.sidebarColor;
      },
      set(value) {
        const color = this.getColorHexOrNull(value);
        this.$store.commit('app/setSidebarColor', color ? color : value);
      }
    },
    primaryColor: {
      get() {
        return this.$store.state.app.primaryColor;
      },
      set(value) {
        if (value) {
          const color = this.getColorHexOrNull(value);
          this.$vuetify.theme.themes.light.primary = color ? color : value;
          this.$store.commit('app/setPrimaryColor', color ? color : value);
        }
      }
    },
    secondaryColor: {
      get() {
        return this.$store.state.app.secondaryColor;
      },
      set(value) {
        if (value) {
          const color = this.getColorHexOrNull(value);
          this.$vuetify.theme.themes.light.secondary = color ? color : value;
          this.$store.commit('app/setSecondaryColor', color ? color : value);
        }
      }
    }
  },
  watch: {
    sideBarOption: {
      handler(val) {
        this.$vuetify.theme.dark = val === 'dark';
      },
      immediate: true
    }
  },
  methods: {
    getColorHexOrNull(color) {
      return this.colors[color] ? this.colors[color].base : null;
    },
    changeLocale(lang) {
      this.$vuetify.lang.current = lang;
    },
    onThemeChange() {
      if (this.sideBarOption == 'light') {
        this.sideBarOption = 'dark';
      } else {
        this.sideBarOption = 'light';
      }
    },
    onThemeOrientationChange() {
      if (this.themeOrientation == 'left') {
        this.themeOrientation = 'right';
      } else {
        this.themeOrientation = 'left';
      }
    }
  }
};
</script>
