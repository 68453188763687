import axios from 'axios';

const state = {
  user: null
};

const mutations = {
  SET_AUTH_USER(state, payload) {
    state.user = payload;
  }
};

const getters = {
  getAvatar: state => (state.user ? state.user.avatar : null),
  getUsername: state => (state.user ? state.user.username : null),
  getUserRole: state => (state.user ? state.user.role : null),
};

const actions = {
  login({ commit }, { username, password }) {
    return new Promise((resolve, reject) => {
      return axios({
        url: '/auth/login',
        method: 'post',
        data: {
          username,
          password
        }
      })
        .then(resp => {
          const user = resp.data.user;
          commit('SET_AUTH_USER', user);
          resolve(resp);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  logout({ commit }) {
    commit('SET_AUTH_USER', null);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
