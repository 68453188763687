<template>
  <v-container fill-height class="center-layout">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AppCenterContainer'
};
</script>

<style lang="sass" scoped>
.center-layout
  position: absolute
  width: 100%
  top: 0
  left: 0
  right: 0
</style>

